<template>
	<a-modal :visible="visible" @ok="ok" @cancel="cancel" :destroyOnClose="true" :maskClosable="false" title="打开网站">
		<ul class="urlList">
			<li v-for="(item, index) in custom_url" :key="index">
				<span>{{ item }}</span>
				<a-icon type="close-circle" theme="filled" @click="del(index)" />
			</li>
		</ul>
	</a-modal>
</template>

<script>
export default {
	props: {
		visible: {
			type: Boolean,
			default: false,
		},
		urls: {
			type: Array,
			default: () => [],
		},
	},
	components: {},
	name: "CustomUrl",
	watch: {
		visible: {
			handler(val) {
				if (val) {
					this.custom_url = JSON.parse(JSON.stringify(this.urls));
				}
			},
		},
	},
	data() {
		return {
			custom_url: [],
		};
	},

	methods: {
		ok() {
			this.$emit("ok", this.custom_url);
			this.$emit("update:visible", false);
		},
		cancel() {
			this.$emit("update:visible", false);
		},
		del(index) {
			this.custom_url.splice(index, 1);
		},
	},
};
</script>
<style lang="less" scoped>
::v-deep .ant-modal {
	.ant-modal-content {
		.ant-modal-title {
			font-weight: bold;
			font-size: 18px;
		}
	}
	.urlList {
		li {
			display: flex;
			align-items: center;
			line-height: 20px;
			padding: 6px 10px;
			border-radius: 15px;
			background-color: rgba(0, 0, 0, 0.05);
			margin-bottom: 6px;
			gap: 0 10px;
			justify-content: space-between;
			span {
				max-width: calc(100% - 30px);
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}

			i {
				color: rgba(0, 0, 0, 0.3);
				transition: all 0.3s;
				cursor: pointer;
				&:hover {
					color: rgba(0, 0, 0, 0.6);
				}
			}
		}
	}
}
</style>
