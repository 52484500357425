<!-- 自定义插件指纹 -->
<template>
	<div>
		<a-modal class="CustomPlugins" :visible="visible" @ok="ok" @cancel="cancel" :destroyOnClose="true" :maskClosable="false" title="自定义插件">
			<a-table :columns="columns" :data-source="list" :row-key="(r, i) => i.toString()">
				<template #operate="row">
					<div class="operate">
						<img @click="openEditForm(row)" src="@/assets/img/rpa/create/icon_edit.svg" alt="" />
						<img @click="deletePlugin(row)" src="@/assets/images/env/icon-delete.svg" alt="" />
					</div>
				</template>
			</a-table>
			<template #footer>
				<a-row type="flex" justify="space-between">
					<a-col>
						<a-button type="primary" @click="openEditForm()">添加插件</a-button>
						<a-button type="primary" @click="clear">恢复默认</a-button>
					</a-col>
					<a-col>
						<a-button @click="cancel">取消</a-button>
						<a-button type="primary" @click="ok">确定</a-button>
					</a-col>
				</a-row>
			</template>
		</a-modal>
		<AddCustomPlugin :visible.sync="customPluginFormVisible" @ok="editPluginFinish" :info="target" />
	</div>
</template>

<script>
import AddCustomPlugin from "./AddCustomPlugin.vue";
const columns = [
	{
		title: "插件名称",
		dataIndex: "name",
		ellipsis: true,
	},
	{
		title: "描述",
		dataIndex: "des",
		ellipsis: true,
	},
	{
		title: "文件名称",
		dataIndex: "filename",
		ellipsis: true,
	},
	{
		title: "mime type",
		dataIndex: "mimetype",
		ellipsis: true,
	},
	{
		title: "mime 描述",
		dataIndex: "mimedes",
		ellipsis: true,
	},
	{
		title: "mime 扩展名",
		dataIndex: "mimeex",
		ellipsis: true,
	},
	{
		title: "操作",
		scopedSlots: { customRender: "operate" },
	},
];
export default {
	components: { AddCustomPlugin },
	name: "CustomPlugins",
	props: {
		visible: {
			type: Boolean,
			default: false,
		},
		pluginInfo: {
			type: Array,
			default: () => [],
		},
	},
	watch: {
		visible: {
			handler(val) {
				if (val) {
					this.list = JSON.parse(JSON.stringify(this.pluginInfo)).map(item => {
						return { ...item, id: Math.random() };
					});
				}
			},
		},
	},
	data() {
		return {
			list: [],
			columns,
			customPluginFormVisible: false,
			target: null,
		};
	},
	methods: {
		ok() {
			this.$emit(
				"ok",
				this.list.map(item => {
					const { id, ...reset } = item;
					return reset;
				})
			);
			this.cancel();
		},
		cancel() {
			this.$emit("update:visible", false);
		},
		openEditForm(row) {
			this.target = row;
			this.customPluginFormVisible = true;
		},
		editPluginFinish(info) {
			console.log(info);
			if (!this.target) {
				this.list.push(info);
			} else {
				Object.assign(this.target, info);
			}
		},
		clear() {
			this.list = [];
		},
		deletePlugin(info) {
			this.list = this.list.filter(item => item.id !== info.id);
		},
	},
};
</script>
<style lang="less">
.CustomPlugins {
	.ant-modal {
		width: 50vw !important;
		.operate {
			img {
				opacity: 0.8;
				transition: all 0.3s;
				cursor: pointer;
				& + img {
					margin-left: 10px;
				}
				&:hover {
					opacity: 1;
					transform: scale(1.1);
				}
			}
		}
	}
}
</style>
