<template>
	<a-modal :visible="visible" @ok="ok" @cancel="cancel" :destroyOnClose="true" :maskClosable="false" title="选择字体" :width="600">
		<div class="getRandom" @click="getRandom">刷一刷</div>
		<a-input placeholder="搜索字体" v-model="keyword" />
		<ul class="all">
			<li v-for="(item, index) in filtedFonts" :key="index">
				<a-checkbox :disabled="lockFonts.includes(item)" @change="checkChange($event, item)" :checked="selectedFonts.includes(item)">{{
					item
				}}</a-checkbox>
			</li>
		</ul>
	</a-modal>
</template>

<script>
export default {
	props: {
		visible: {
			type: Boolean,
			default: false,
		},
		allFonts: {
			type: Array,
			default: () => [],
		},
		lockFonts: {
			type: Array,
			default: () => [],
		},
		selectedFontList: {
			type: Array,
			default: () => [],
		},
	},
	components: {},
	name: "SelectFont",
	data() {
		return {
			keyword: "",
			all: [],
			selectedFonts: [],
		};
	},
	computed: {
		filtedFonts() {
			if (this.keyword) return this.all.filter(item => item.includes(this.keyword));
			return this.all;
		},
	},
	watch: {
		visible(newVal) {
			if (newVal) {
				this.all = [...this.lockFonts, ...this.allFonts];
				this.selectedFonts = [...this.selectedFontList];
			}
		},
	},
	methods: {
		ok() {
			this.$emit("ok", this.selectedFonts);
			this.$emit("update:visible", false);
		},
		cancel() {
			this.$emit("update:visible", false);
		},
		checkChange(e, font) {
			const isChecked = e.target.checked;
			if (isChecked) {
				this.selectedFonts.push(font);
			} else {
				const index = this.selectedFonts.findIndex(item => item === font);
				this.selectedFonts.splice(index, 1);
			}
		},
		getRandom() {
			const allFontlist = this.allFonts.toSorted((a, b) => Math.random() - 0.5);
			const all_length = allFontlist.length;
			const randomRange = [1, all_length];
			const random_length = Math.floor(Math.random() * (randomRange[1] - randomRange[0] + 1)) + randomRange[0];
			this.selectedFonts = [...this.lockFonts, ...allFontlist.slice(0, random_length)];
		},
	},
};
</script>
<style lang="less" scoped>
::v-deep .ant-modal {
	.ant-modal-content {
		.ant-modal-title {
			font-weight: bold;
			font-size: 18px;
		}
	}
	.getRandom {
		margin-top: -20px;
		padding-left: 18px;
		background: url(../../../../assets/img/xinjian/shua.png) no-repeat center left;
		cursor: pointer;
		margin-bottom: 10px;
		width: fit-content;
		color: #598dff;
	}
	.all {
		height: 300px;
		overflow: auto;
		margin-top: 20px;
		li {
			height: 30px;
			display: flex;
			align-items: center;
		}
	}
}
</style>
