<template>
	<a-modal
		class="AddCustomPlugin"
		:visible="visible"
		@ok="ok"
		@cancel="cancel"
		:destroyOnClose="true"
		:maskClosable="false"
		:title="info ? '编辑插件' : '添加插件'">
		<a-form-model layout="vertical" :model="formData" :rules="rules" ref="ruleForm">
			<a-form-model-item label="插件名称" prop="name">
				<a-input v-model="formData.name" />
			</a-form-model-item>
			<a-form-model-item label="描述" prop="des">
				<a-input v-model="formData.des" />
			</a-form-model-item>
			<a-form-model-item label="文件名称" prop="filename">
				<a-input v-model="formData.filename" />
			</a-form-model-item>
			<a-form-model-item label="mime type" prop="mimetype">
				<a-input v-model="formData.mimetype" />
			</a-form-model-item>
			<a-form-model-item label="mime 描述" prop="mimedes">
				<a-input v-model="formData.mimedes" />
			</a-form-model-item>
			<a-form-model-item label="mime 扩展名" prop="mimeex">
				<a-input v-model="formData.mimeex" />
			</a-form-model-item>
		</a-form-model>
	</a-modal>
</template>

<script>
const defaultConfig = {
	name: "",
	des: "",
	filename: "",
	mimetype: "",
	mimedes: "",
	mimeex: "",
};
export default {
	components: {},
	name: "AddCustomPlugin",
	props: {
		visible: {
			type: Boolean,
			default: false,
		},
		info: {
			type: Object,
		},
	},
	watch: {
		visible: {
			handler(val) {
				if (val && this.info) {
					this.formData = { ...defaultConfig, ...this.info };
				} else {
					this.formData = { ...defaultConfig };
				}
			},
		},
	},
	data() {
		return {
			formData: {},
			rules: {
				name: [
					{ required: true, message: "请输入插件名称", trigger: "blur" },
					{ max: 100, message: "最大长度为100个字符", trigger: "blur" },
				],
				des: [
					{ required: true, message: "请输入描述", trigger: "blur" },
					{ max: 100, message: "最大长度为100个字符", trigger: "blur" },
				],
				filename: [
					{ required: true, message: "请输入文件名称", trigger: "blur" },
					{ max: 100, message: "最大长度为100个字符", trigger: "blur" },
				],
				mimetype: [
					{ required: true, message: "请输入mime type", trigger: "blur" },
					{ max: 100, message: "最大长度为100个字符", trigger: "blur" },
				],
				mimedes: [
					{ required: true, message: "请输入mime 描述", trigger: "blur" },
					{ max: 100, message: "最大长度为100个字符", trigger: "blur" },
				],
				mimeex: [
					{ required: true, message: "请输入mime 扩展名", trigger: "blur" },
					{ max: 100, message: "最大长度为100个字符", trigger: "blur" },
				],
			},
		};
	},
	methods: {
		ok() {
			this.$refs.ruleForm.validate(valid => {
				if (valid) {
					this.$emit("ok", { ...JSON.parse(JSON.stringify(this.formData)), id: Math.random() });
					this.cancel();
				} else {
					return false;
				}
			});
		},
		cancel() {
			this.$refs.ruleForm.resetFields();
			this.$emit("update:visible", false);
		},
	},
};
</script>
<style lang="less" scoped></style>
