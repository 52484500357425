import { render, staticRenderFns } from "./AddCustomPlugin.vue?vue&type=template&id=2b53f438&scoped=true"
import script from "./AddCustomPlugin.vue?vue&type=script&lang=js"
export * from "./AddCustomPlugin.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b53f438",
  null
  
)

export default component.exports