<template>
	<div class="EnviromentPreview">
		<h2>概要</h2>
		<a-row type="flex">
			<a-col flex="100px">浏览器</a-col>
			<a-col flex="200px">YtBrowser</a-col>
		</a-row>
		<a-row type="flex">
			<a-col flex="100px">User Agent</a-col>
			<a-col flex="200px">{{ config.user_agent }}</a-col>
		</a-row>
		<a-row type="flex">
			<a-col flex="100px">时区</a-col>
			<a-col flex="200px">{{ config.is_custom_timezone ? config.timezone : "基于IP生成自定义时区" }}</a-col>
		</a-row>
		<a-row type="flex">
			<a-col flex="100px">WebRTC</a-col>
			<a-col flex="200px">{{ webrtc_set }}</a-col>
		</a-row>
		<a-row type="flex">
			<a-col flex="100px">地理位置</a-col>
			<a-col flex="200px">{{ location }}</a-col>
		</a-row>
		<a-row type="flex">
			<a-col flex="100px">语言</a-col>
			<a-col flex="200px">{{ language }}</a-col>
		</a-row>
		<a-row type="flex">
			<a-col flex="100px">分辨率</a-col>
			<a-col flex="200px">{{ config.resolution }}</a-col>
		</a-row>
		<a-row type="flex">
			<a-col flex="100px">Canvas</a-col>
			<a-col flex="200px">{{ config.canvas ? "噪音" : "关闭" }}</a-col>
		</a-row>
		<a-row type="flex">
			<a-col flex="100px">WebGL图像</a-col>
			<a-col flex="200px">{{ config.webgl_graphic ? "噪音" : "关闭" }}</a-col>
		</a-row>
		<a-row type="flex">
			<a-col flex="100px">WebGL元数据</a-col>
			<a-col flex="200px">{{ config.webgl_des.status ? "自定义" : "关闭" }}</a-col>
		</a-row>
		<a-row type="flex">
			<a-col flex="100px">AudioContext</a-col>
			<a-col flex="200px">{{ config.audio_context ? "噪音" : "关闭" }}</a-col>
		</a-row>
		<a-row type="flex">
			<a-col flex="100px">媒体设备</a-col>
			<a-col flex="200px">{{ config.media_device ? "噪音" : "关闭" }}</a-col>
		</a-row>
		<a-row type="flex">
			<a-col flex="100px">ClientRects</a-col>
			<a-col flex="200px">{{ config.client_rects ? "噪音" : "关闭" }}</a-col>
		</a-row>
		<a-row type="flex">
			<a-col flex="100px">SpeechVoices</a-col>
			<a-col flex="200px">{{ config.speech_voices ? "噪音" : "关闭" }}</a-col>
		</a-row>
		<a-row type="flex">
			<a-col flex="100px">硬件并发数</a-col>
			<a-col flex="200px">{{ config.hardware_concurrency }}</a-col>
		</a-row>
		<a-row type="flex">
			<a-col flex="100px">设备内存</a-col>
			<a-col flex="200px">{{ config.memory_size }}</a-col>
		</a-row>
		<a-row type="flex">
			<a-col flex="100px">MAC地址</a-col>
			<a-col flex="200px">{{ config.mac_address || "关闭" }}</a-col>
		</a-row>
	</div>
</template>

<script>
import { languageList } from "@/utils/languageList";
export default {
	components: {},
	name: "EnviromentPreview",
	props: ["enviroment", "config"],
	data() {
		return {};
	},
	computed: {
		webrtc_set() {
			switch (this.config.webrtc_set) {
				case 0:
					return "禁用";
				case 1:
					return "真实";
				case 2:
					return "替换";
			}
		},
		location() {
			switch (this.config.location.location_status) {
				case 0:
					return "询问";
				case 1:
					return "允许";
				case 2:
					return "禁止";
			}
		},
		language() {
			if (this.config.language) {
				return "基于IP生成对应的国家语言";
			} else {
				const list = this.config.langSortList.map(item => {
					const target = languageList.find(l => l.value === item);
					return target.name;
				});
				return list.join(",");
			}
		},
	},
};
</script>
<style lang="less" scoped>
.EnviromentPreview {
	width: 360px;
	margin-left: 16px;
	padding: 20px;
	background-color: #fff;
	height: fit-content;
	position: sticky;
	top: 0;
	::v-deep .ant-row-flex {
		margin-top: 16px;
		justify-content: space-between;
		.ant-col {
			&:nth-child(1) {
				font-size: 14px;
				font-weight: 400;
				color: #999999;
			}
			&:nth-child(2) {
				font-size: 14px;
				font-weight: 400;
				color: #28282a;
				text-align: right;
			}
		}
	}
}
</style>
